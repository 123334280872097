<template>
    <div>
      <div class="grid">
        <div
          v-for="experience in experiences"
          :key="experience._id"
          class="grid-item"
        >
        <div class="jessica-experience">
            <div>
                <div class="jessica-picture-container">
                <router-link :to="`/stories/${experience.slug.current}`">
                    <img class="jessica-img" :src="imageUrlFor(experience.image.image)" :alt="experience.name" />
                </router-link>
            </div>
            <h3 class="jessica-name">{{ experience.name }}</h3>
            <div v-if="experience && experience.testimony" class="testimony-wrapper">
                <h4 class="testimony">"{{ experience.testimony }}"</h4>
            </div>
            </div>


          <router-link class="hear-story" :to="`/stories/${experience.slug.current}`">
            HEAR {{ experience.name }}'S STORY
          </router-link>
        </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import sanity from "../client"; // Importa il client Sanity
  import imageUrlBuilder from "@sanity/image-url";
  const imageBuilder = imageUrlBuilder(sanity);

  export default {
    data() {
      return {
        experiences: [], // Stato per salvare tutte le esperienze
      };
    },
    methods: {
    imageUrlFor(source) {
    return imageBuilder.image(source);
},
    },
    async created() {
      try {
        // Query per recuperare tutte le esperienze
        const query = `*[_type == "experience"] | order(experienceOrder asc) {
            _id,
            experienceOrder,
            image,
            name,
            testimony,
            slug
        }`;

        // Recupera i dati usando Sanity
        const result = await sanity.fetch(query);

        // Salva i risultati nello stato
        this.experiences = result;
      } catch (error) {
        console.error("Error fetching experiences:", error);
      }
    },
  };
  </script>

  <style scoped>
  /* Stile per la griglia */
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 6vw), 1fr));
    column-gap: 3vw;
    row-gap: 6vw;
    padding: 0;
    max-width: 52vw;
    margin: 9vw auto;
  }

  .grid-item {
    text-align: center;
  }

  .grid-item h3 {
    text-transform: uppercase;
  }

  .grid-item h4.testimony {
    font-size: 2vw;
    margin-bottom: 0;
    line-height: 2.5vw;
    font-weight: 400;
    text-align: left;
}

  .grid .jessica-experience {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .grid .jessica-picture-container {
    position: relative;
    width: 100%;
    height: 25vw;
    top: 0;
    left: 0;
    overflow: hidden;
    background: none;
}
.grid .jessica-name {
    margin: -1vw auto;
    position: relative;
    font-size: 5vw;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
}

  .grid-item .jessica-img {
    object-fit: cover;
    height: 100%;
  }
  .hear-story {
        background: none;
        width: 16vw;
        height: 5vw;
        font-size: .9vw;
        border: .2vw solid #d4c09e;
        color: #544a3f;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Arial, sans-serif !important;
        font-weight: 700;
        text-transform: uppercase;
        margin: 2vw auto 0;
    }

    @media (max-aspect-ratio: 200 / 200) {
        .grid {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 12vw;
    padding: 0;
    max-width: 84vw;
    margin: 22vw auto 15vw;
}

        .grid .jessica-picture-container {
            margin-top: 0;
            height: 84vw;
        }
        .grid .jessica-name {
    margin: -2.5vw auto;
    font-size: 15vw;
}
.grid-item h4.testimony {
    font-size: 5vw;
    line-height: 5.5vw;
    margin-top: 4vw;
}

.hear-story {
    width: 50vw;
    height: 15vw;
    font-size: 2.5vw;
    border: .5vw solid #d4c09e;
    margin: 5vw auto 0;
}


    }
  </style>